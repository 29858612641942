.section-newsletter-blog {
  background: linear-gradient(
    to bottom,
    fade-out(#6ce5e0, 1) 0%,
    fade-out(#6ce5e0, 0.7) 100%
  );
  position: relative;

  @include tablet {
    display: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 730px;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);

    &.appear {
      opacity: 1;
      transform: translateY(0);
    }

    &-title {
      color: #1e2022;
      font-size: 30px;
      font-weight: 500;
      line-height: 42px;
      margin-bottom: 10px;
    }

    &-description {
      text-align: center;

      p {
        color: #77838f;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
      }
    }

    &-form {
      display: flex;
      align-items: center;
      margin-top: 35px;

      .field-group {
        position: relative;
        width: 380px;
        height: 52px;
        z-index: 1;

        .field {
          background-color: white;
          color: #77838f;
          font-size: 14px;
          font-weight: 500;
          border-radius: 8px;
          padding: 0 60px 0 15px;
          width: 100%;
          border: none;
          display: block;
          height: 52px;
          transition-property: background-color, color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &::placeholder {
            color: #77838f;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }
        }

        &.invalid .field {
          background-color: fade-out(red, 0.5);
          color: white;

          &::placeholder {
            color: white;
          }
        }
      }

      .form-submit {
        position: relative;
        margin-left: -45px;
        z-index: 2;

        button {
          background-color: #19234a;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          overflow: hidden;
        }
      }
    }
  }
}
