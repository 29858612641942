#main-footer {
  background-color: #11252d;
  position: relative;
  padding: 75px 0 0;

  @include tablet {
    padding: 75px 0 0;
  }

  .background-1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @include tablet {
      display: none;
    }

    img {
      display: block;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-child(1) {
      border-bottom: 1px solid #395c6b;
      padding-bottom: 35px;
      margin-bottom: 35px;

      @include tablet {
        flex-direction: column;
      }
    }

    &:nth-child(2) {
      margin-bottom: 35px;

      @include tablet {
        flex-wrap: wrap;
      }
    }

    &:nth-child(3) {
      padding-bottom: 35px;

      @include tablet {
        flex-direction: column;
      }
    }

    .left,
    .right {
      width: 455px;
      flex-shrink: 0;

      @include tablet {
        width: 100%;
      }

      &-links {
        &--title {
          color: white;
          font-family: font(title);
          font-size: 18px;
          font-weight: normal;
        }

        &--items {
          display: flex;
          align-items: center;
          margin-top: 15px;

          @include tablet {
            flex-direction: column;
            align-items: flex-start;
          }

          .item {
            &:not(:last-child) {
              margin-right: 30px;

              @include tablet {
                margin: 0 0 15px;
              }
            }

            a {
              color: #fff8bb;
              font-family: font(title);
              font-size: 19px;
              font-weight: 500;
              display: flex;
              align-items: center;

              svg {
                position: relative;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .left {
      @include tablet {
        margin-bottom: 20px;
      }

      &-title {
        color: white;
        font-family: font(title);
        font-size: 21px;
        font-weight: normal;
        margin-top: 48px;
      }

      &-social {
        margin-top: 30px;

        ul {
          display: flex;
          align-items: center;

          li {
            display: inline-block;

            &:not(:last-child) {
              margin-right: 35px;
            }

            a {
              display: inline-block;
            }
          }
        }
      }

      &-info {
        width: 300px;
        margin-top: 55px;

        p {
          color: #89a7b6;
          font-size: 12px;
          font-weight: 300;
          line-height: 1.4;
        }
      }

      &-links {
        margin-top: 40px;
      }
    }

    .right {
      @include tablet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-newsletter {
        @include tablet {
          margin-top: 40px;
          order: 2;
        }

        &--title {
          color: #fff8bb;
          font-size: 23px;
          font-weight: 500;

          @include tablet {
            font-size: 18px;
          }
        }

        &--description {
          width: 345px;
          margin-top: 15px;

          @include tablet {
            width: 100%;
            max-width: 345px;
          }

          p {
            color: white;
            font-size: 15px;
            font-weight: normal;
            line-height: 1.3;
            opacity: 0.7;
          }
        }

        &-form {
          display: flex;
          width: 100%;
          margin-top: 30px;
          border-radius: 6px;
          overflow: hidden;

          @include tablet {
            flex-direction: column;
            align-items: flex-start;
          }

          .field-group {
            flex-grow: 1;

            @include tablet {
              flex-grow: unset;
              width: 100%;
            }

            .field {
              background-color: #142d36;
              color: white;
              font-size: 14px;
              font-weight: normal;
              height: 50px;
              padding: 0 20px;
              position: relative;
              border: 1px solid #fff8bb;
              border-radius: 6px 0 0 6px;
              width: 100%;
              display: block;
              transition-property: border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              @include tablet {
                border-radius: 6px 6px 0 0;
              }

              &::placeholder {
                color: white;
                opacity: 0.7;
              }
            }

            &.invalid .field {
              border-color: red;
            }
          }

          .form-submit {
            position: relative;
            width: 150px;
            height: 50px;
            flex-shrink: 0;

            @include tablet {
              width: 100%;
            }

            button {
              background-color: #fff8bb;
              color: black;
              font-size: 14px;
              font-weight: 600;
              position: relative;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0 6px 6px 0;

              @include tablet {
                border-radius: 0 0 6px 6px;
              }
            }
          }
        }
      }

      &-logos {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 60px;

        @include tablet {
          order: 3;
        }

        .logo {
          position: relative;

          img {
            display: block;
          }

          &:not(:last-child) {
            margin-right: 65px;
          }
        }
      }

      &-links {
        margin-top: 10px;

        @include tablet {
          margin: 0;
          order: 1;
        }
      }
    }

    .column {
      @include tablet {
        width: 50%;
        margin-bottom: 30px;
      }
      @include miniPhone {
        width: 100%;
      }

      &:last-child {
        ul li {
          text-align: right;

          @include tablet {
            text-align: left;
          }
        }
      }

      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 3px;
          }

          &.first span,
          &.first a {
            color: white;
            font-size: 12px;
            font-weight: normal;
            display: inline-block;
            margin-bottom: 10px;
          }

          a {
            color: #89a7b6;
            font-size: 11px;
            font-weight: normal;
            line-height: 1.7;
            display: inline-block;
          }
        }
      }
    }

    .copyright,
    .signature {
      color: #89a7b6;
      font-size: 12px;
      font-weight: 300;

      @include tablet {
        text-align: center;
        width: 100%;
      }
    }

    .signature {
      text-align: right;

      @include tablet {
        text-align: center;
        margin-top: 20px;
      }
    }
  }

  .background-2 {
    position: relative;
    width: 100%;
    height: 90px;
    display: block;

    img {
      @extend %cover;
    }
  }
}
