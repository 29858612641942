.section-newsletter {
  background-image: linear-gradient(
    to bottom,
    fade-out(#e3fcff, 1),
    #e3fcff 60%
  );
  padding: 90px 0 60px;

  @include tablet {
    display: none;
  }

  .wrapper {
    position: relative;
    width: 1020px;
    height: 750px;
    margin: 0 auto;
    padding: 210px 0 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);

    &.appear {
      opacity: 1;
    }

    &-background {
      position: absolute;
      top: 50%;
      left: calc(50% - 40px);
      transform: translate(-50%, -50%);
      pointer-events: none;
      user-select: none;
      z-index: 1;

      img {
        display: block;
      }
    }

    &-content {
      position: relative;
      width: 720px;
      margin: 0 auto;
      z-index: 2;

      &--title {
        text-align: center;

        p {
          color: #142d36;
          font-family: font(title);
          font-size: 40px;
          font-weight: 300;
          line-height: 1.1;

          strong {
            font-weight: 500;
          }
        }
      }

      &--subtitle {
        text-align: center;
        margin-top: 35px;

        p {
          color: #142d36;
          font-family: font(title);
          font-size: 31px;
          font-weight: 300;
          line-height: 1.1;

          strong {
            font-weight: 500;
          }
        }
      }

      &--form {
        display: flex;
        width: 455px;
        margin: 20px auto 0;
        border-radius: 6px;
        overflow: hidden;

        .field-group {
          flex-grow: 1;

          .field {
            background-color: #142d36;
            color: white;
            font-size: 14px;
            font-weight: normal;
            height: 50px;
            padding: 0 20px;
            position: relative;
            border: 1px solid #3480f6;
            border-radius: 6px 0 0 6px;
            width: 100%;
            display: block;

            &::placeholder {
              color: white;
              opacity: 0.7;
            }
          }
        }

        .form-submit {
          position: relative;
          width: 150px;
          height: 50px;
          flex-shrink: 0;

          button {
            background-color: #3480f6;
            color: white;
            font-size: 14px;
            font-weight: 600;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }
  }
}
