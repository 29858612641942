#main-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;

  .flex-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .logo {
        a {
          position: relative;
          display: inline-block;

          span {
            color: #3480f6;
            font-family: font(title);
            font-size: 17px;
            font-weight: bold;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: calc(100% + 12px);
            transform: translateY(-50%);

            @include tablet {
              top: calc(50% - 1px);
            }
          }

          @include tablet {
            img {
              width: 120px;
            }
          }
        }
      }

      .categories {
        position: relative;
        margin-left: 110px;

        @include tablet {
          display: none;
        }

        &-title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;

          span {
            color: #142d36;
            font-size: 13px;
            font-weight: 500;
            display: inline-block;
            margin-right: 10px;
          }
        }

        &-dropdown {
          position: absolute;
          top: 0;
          left: -24px;
          width: auto;
          padding-top: 38px;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          transition-property: opacity, visibility;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          z-index: 2;

          ul {
            background-color: #efefef;
            border-radius: 8px;
            padding: 10px 24px;
            position: relative;
            display: block;
            white-space: nowrap;
            overflow: hidden;

            li {
              position: relative;
              display: block;
              width: 100%;

              a {
                color: #6e6e6e;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                height: 40px;
                transition-property: color, transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);

                &:hover {
                  color: #3480f6;
                  transform: translateX(5px);
                }
              }
            }
          }
        }

        &:hover {
          .categories-dropdown {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .search {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 33px;

        @include tablet {
          display: none;
        }

        .form-submit {
          position: relative;
          width: 46px;
          height: 46px;
          margin-right: -46px;
          z-index: 2;

          button {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg path {
              stroke: fade-out(black, 0.7);
            }
          }
        }

        .field-group {
          position: relative;
          flex-shrink: 0;
          z-index: 1;

          .field {
            background-color: transparent;
            color: fade-out(black, 0.7);
            font-size: 13px;
            font-weight: 500;
            border: 1px solid fade-out(black, 0.7);
            border-radius: 8px;
            width: 240px;
            height: 46px;
            padding: 0 15px 0 50px;

            &::placeholder {
              color: fade-out(black, 0.7);
            }
          }
        }
      }

      .return {
        // margin-right: 33px;
        @include tablet {
          display: none;
        }

        a {
          background-color: #11252d;
          color: white;
          font-size: 14px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 180px;
          height: 46px;
          border: 1px solid #11252d;
          border-radius: 8px;
          transition-property: background-color, color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:hover {
            background-color: white;
            color: #11252d;
          }
        }
      }

      .navigation {
        display: flex;
        align-items: center;
        margin-right: 40px;

        @include tablet {
          display: none;
        }

        .item {
          position: relative;

          &:not(:last-child) {
            margin-right: 38px;
          }

          &-title {
            color: #142d36;
            font-size: 12px;
            font-weight: normal;
            display: flex;
            align-items: center;

            > svg {
              position: relative;
              margin-left: 5px;
            }
          }

          &-dropdown {
            position: absolute;
            top: 0;
            left: -24px;
            width: auto;
            padding-top: 38px;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            transition-property: opacity, visibility;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 2;

            ul {
              background-color: #efefef;
              border-radius: 8px;
              padding: 10px 24px;
              position: relative;
              display: block;
              white-space: nowrap;
              overflow: hidden;

              li {
                position: relative;
                display: block;
                width: 100%;

                a {
                  color: #6e6e6e;
                  font-size: 12px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  height: 40px;
                  transition-property: color, transform;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);

                  &:hover {
                    color: #3480f6;
                    transform: translateX(5px);
                  }
                }
              }
            }
          }

          &:hover {
            .item-dropdown {
              opacity: 1;
              visibility: visible;
              pointer-events: initial;
            }
          }
        }
      }

      .cta {
        // margin-right: 35px;

        @include tablet {
          display: none;
        }

        a,
        button {
          color: #057396;
          font-size: 12px;
          font-weight: normal;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 14px;
          height: 34px;
          border: 1px solid #057396;
          border-radius: 8px;
        }
      }

      .languages {
        @include tablet {
          display: none;
        }

        &-group {
          display: flex;
          align-items: center;

          &--image {
            display: block;

            img {
              display: block;
            }
          }

          &--icon {
            position: relative;
            top: -2px;
            margin-left: 7px;
          }
        }
      }

      .menu-toggler {
        position: relative;
        width: 25px;
        height: 18px;
        display: none;

        @include tablet {
          display: block;
        }

        span {
          background-color: #11252d;
          position: absolute;
          right: 0;
          left: 0;
          display: block;
          height: 1px;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2) {
            top: 8px;
          }

          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
    }
  }

  &[theme='single'] {
    background-color: white;
  }
}
