.gk-modal {
  background-color: #11252d;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 95;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    &-close {
      position: absolute;
      top: 45px;
      right: 45px;
      z-index: 5;

      @media (max-width: 767px) {
        top: 25px;
        right: 25px;
      }

      svg {
        transform: scale(1.5);
      }
    }
  }
}
