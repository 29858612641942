#main-menu {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, transform;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 100;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  .logo {
    position: absolute;
    top: 22px;
    left: 25px;
    z-index: 2;

    a {
      position: relative;
      display: inline-block;

      img {
        width: 120px;
      }
    }
  }

  .close {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 2;

    svg path {
      stroke: #152d36;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    .navigation {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item {
        position: relative;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &-title {
          color: #142d36;
          font-size: 18px;
          font-weight: normal;
          display: flex;
          align-items: center;

          > svg {
            position: relative;
            margin-left: 5px;
          }
        }

        &-dropdown {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: auto;
          padding-top: 38px;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          transition-property: opacity, visibility;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          z-index: 2;

          ul {
            background-color: #efefef;
            border-radius: 8px;
            padding: 10px 24px;
            position: relative;
            display: block;
            white-space: nowrap;
            overflow: hidden;

            li {
              position: relative;
              display: block;
              width: 100%;

              a {
                color: #6e6e6e;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 40px;
              }
            }
          }
        }

        &:hover {
          .item-dropdown {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
          }
        }
      }
    }

    .cta {
      margin-top: 30px;

      a,
      button {
        color: #057396;
        font-size: 12px;
        font-weight: normal;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
        height: 34px;
        border: 1px solid #057396;
        border-radius: 8px;
      }
    }

    .search {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 30px;

      .form-submit {
        position: relative;
        width: 46px;
        height: 46px;
        margin-right: -46px;
        z-index: 2;

        button {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg path {
            stroke: fade-out(black, 0.7);
          }
        }
      }

      .field-group {
        position: relative;
        flex-shrink: 0;
        z-index: 1;

        .field {
          background-color: transparent;
          color: fade-out(black, 0.7);
          font-size: 13px;
          font-weight: 500;
          border: 1px solid fade-out(black, 0.7);
          border-radius: 8px;
          width: 240px;
          height: 46px;
          padding: 0 15px 0 50px;

          &::placeholder {
            color: fade-out(black, 0.7);
          }
        }
      }
    }
  }
}
