@font-face {
  font-family: 'Praktika Rounded';
  src: url('../../assets/fonts/PraktikaRounded-Bold.woff2') format('woff2'),
    url('../../assets/fonts/PraktikaRounded-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Praktika Rounded';
  src: url('../../assets/fonts/PraktikaRounded-Medium.woff2') format('woff2'),
    url('../../assets/fonts/PraktikaRounded-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Praktika Rounded';
  src: url('../../assets/fonts/PraktikaRounded-Regular.woff2') format('woff2'),
    url('../../assets/fonts/PraktikaRounded-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
