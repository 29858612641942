.button {
  padding: 0 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 59px;
  border: 1px solid transparent;
  border-radius: 8px;
  transition-property: background-color, border-color;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  span {
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
    text-align: left;
    transition-property: color;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
  }

  svg {
    position: relative;
    bottom: -1px;
    flex-shrink: 0;
    margin-left: 10px;
    transition-property: transform;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    path,
    line {
      transition-property: stroke;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
    }
  }

  &:hover svg {
    transform: translateX(5px);
  }

  &.button-down {
    &:hover svg {
      transform: translateY(5px);
    }
  }

  &-grey {
    background-color: #e3fcff;

    span {
      color: #395c6b;
    }

    svg path,
    svg line {
      stroke: #395c6b;
    }

    &:hover {
      background-color: #11252d;

      span {
        color: white;
      }

      svg path,
      svg line {
        stroke: white;
      }
    }
  }

  &-dark {
    background-color: #11252d;
    border-color: #11252d;

    span {
      color: white;
    }

    svg path,
    svg line {
      stroke: white;
    }

    &:hover {
      background-color: white;

      span {
        color: #11252d;
      }

      svg path,
      svg line {
        stroke: #11252d;
      }
    }
  }

  &-yellow {
    background-color: #fff8bb;

    span {
      color: #19234a;
    }

    svg path,
    svg line {
      stroke: #19234a;
    }

    &:hover {
      background-color: #11252d;

      span {
        color: white;
      }

      svg path,
      svg line {
        stroke: white;
      }
    }
  }

  &-blue {
    background-color: #3480f6;

    span {
      color: white;
    }

    svg path,
    svg line {
      stroke: white;
    }

    &:hover {
      background-color: white;

      span {
        color: #11252d;
      }

      svg path,
      svg line {
        stroke: #11252d;
      }
    }
  }

  &-blue-light {
    background-color: #6ce5e0;

    span {
      color: #19234a;
    }

    svg path,
    svg line {
      stroke: #19234a;
    }

    &:hover {
      background-color: #11252d;

      span {
        color: white;
      }

      svg path,
      svg line {
        stroke: white;
      }
    }
  }

  &-blue-dark {
    background-color: #057396;

    span {
      color: white;
    }

    svg path,
    svg line {
      stroke: white;
    }

    &:hover {
      background-color: #11252d;

      span {
        color: white;
      }

      svg path,
      svg line {
        stroke: white;
      }
    }
  }
}
