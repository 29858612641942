$mini-phone-width: 355px;
$phone-width: 767px;
$tablet-width: 1023px;
$desktop-width: 1140px;

@mixin miniPhone {
  @media (max-width: #{$mini-phone-width}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}
